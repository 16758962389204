// 考试记录 -- 详情
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a v-if="toStu == '1'" href="javascript:;" @click="$router.push('/web/specialTestList')">专项考试</a>
          <a v-else href="javascript:;" @click="$router.push('/web/JGspecialTestList')">专项考试</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试记录</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">答题详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <div class="flexcb">
              <h3>考试成绩信息</h3>
              <el-button
                style="padding: 8px 20px"
                class="bgc-bv"
                @click="$router.back()"
                >返回</el-button
              >
            </div>

            <div
              class="flexdc"
              style="
                align-items: flex-start;
                background: rgba(241, 247, 253, 1);
              "
            >
              <div style="padding: 20px" class="rowJsontitle">
                <div>
                  <img src="@/assets/paperSource.png" alt />
                  <span>
                    本次成绩：
                    <em style="color: #5c6be8; font-size: 20px">
                      {{ examScore || 0 }} </em
                    >分
                  </span>
                </div>
                <div>
                  <img src="@/assets/success.png" alt />
                  <span>
                    正确题数：
                    <em style="color: #5c6be8; font-size: 20px">
                      {{ correctNum || 0 }} </em
                    >题
                  </span>
                </div>
                <div>
                  <img src="@/assets/error.png" alt />
                  <span>
                    错误题数：
                    <em style="color: #5c6be8; font-size: 20px">
                      {{ errorNum || 0 }} </em
                    >题
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div
              class="bottom searchbox"
              style="padding-top: 0; padding-right: 1rem"
            >
              <h3>试卷题目信息</h3>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div
                class="listExam"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div class="list-item df">
                  <img
                    src="@/assets/success.png"
                    alt
                    style="width: 16px; height: 16px; margin: 5px"
                    v-if="item.questionAnswer == item.userAnswer"
                  />
                  <img
                    src="@/assets/error.png"
                    alt
                    style="width: 16px; height: 16px; margin: 5px"
                    v-else
                  />
                  <span
                    class="tixing"
                    :class="{
                      tixing1: item.questionType == 0,
                      tixing2: item.questionType == 1,
                      tixing3: item.questionType == 2,
                      tixing4: item.questionType == 3,
                    }"
                    >{{
                      $setDictionary("QUESTIONTYPE", item.questionType)
                    }}</span
                  >
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ indexMethod(index) }}、</span>
                      <span v-html="item.questionTitle" class="questionTitle">{{ item.questionTitle }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 0">
                      <el-radio-group
                        v-model="item.myAnswer"
                        disabled
                        class="radioGroup"
                      >
                        <el-radio
                          :label="item.optionList[ind].optionCode"
                          v-for="(el, ind) in charArr.slice(
                            0,
                            item.optionList.length
                          )"
                          :key="ind"
                        >
                          {{ el }}.{{ item.optionList[ind].optionContent }}
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <div class="option" v-if="item.questionType == 1">
                      <el-checkbox-group
                        v-model="item.myAnswer"
                        class="radioGroup"
                        disabled
                      >
                        <el-checkbox
                          :label="item.optionList[ind].optionCode"
                          v-for="(el, ind) in charArr.slice(
                            0,
                            item.optionList.length
                          )"
                          :key="ind"
                        >
                          {{ el }}.{{ item.optionList[ind].optionContent }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <div class="option" v-if="item.questionType == 2">
                      <el-radio-group v-model="item.myAnswer" disabled>
                        <el-radio label="T">正确</el-radio>
                        <el-radio label="F">错误</el-radio>
                      </el-radio-group>
                    </div>
                    <div class="option" v-if="item.questionType == 3">
                      <div class="analysis">{{ item.myAnswer }}</div>
                    </div>
                    <div class="analysis" v-if="item.questionType == '2'">
                      正确答案：{{ item.questionAnswer == "T" ? "正确" : "错误" }}
                    </div>
                    <div class="analysis" v-else>
                      正确答案：{{
                        item.optionList.length
                          ? getAnswer(item.optionList)
                          : item.questionAnswer
                      }}
                    </div>
                    <div style="display: flex">
                      <p>答案解析：</p>
                      <span class="analysis" v-html="item.questionAnalysis">
                        {{ item.questionAnalysis }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getDatas"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "specialTestRecordDetail",
  components: {
    // Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      id: "",
      correctNum: "",
      errorNum: "",
      examScore: "",
      charArr: ["A", "B", "C", "D", "E", "F", "G", "H"],
      toStu:'1'
    };
  },
  created() {
    // this.init();
  },
  mounted() {},
  computed: {},
  methods: {
    init() {
      this.id = this.$route.query.id;
      this.toStu = this.$route.query.toStu;

      this.getList();
    },
    getAnswer(list) {
      let val = "";
      list.forEach((el, index) => {
        if (el.optionIsCorrect) {
          val += this.charArr[index];
        }
      });
      return val;
    },
    getList() {
      const params = {
        detailsId: this.id,
      };
      this.$post("/biz/exam/user/details/queryPaperByDetailsId", params).then(
        (ret) => {
          console.log(ret);
          console.log(JSON.parse(ret.data.questionDetail));
          this.correctNum = ret.data.correctNum;
          this.errorNum = ret.data.errorNum;
          this.examScore = ret.data.examScore;
          this.paperList = JSON.parse(ret.data.questionDetail) || [];

          this.apiData.total = this.paperList.length;
          this.apiData.size = this.pageSize || 10;
          this.apiData.current = this.pageNum || 1;
          this.getDatas();
        }
      );
    },
    getDatas(pageNum = 1) {
      this.pageNum = pageNum;
      // 分页 slice
      let p_num = pageNum - 1;

      this.tableData = this.paperList.slice(
        p_num * this.pageSize,
        pageNum * this.pageSize
      );
      this.tableData = this.tableData.map((el) => {
        if (el.questionType == "1") {
          el.myAnswer = el.userAnswer.split(",");
        } else {
          el.myAnswer = el.userAnswer;
        }
        return el;
      });
      console.log(this.tableData);
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDatas();
    },
    indexMethod(index) {
      return (this.pageNum - 1) * this.pageSize + index + 1;
    },
    // 分页
    paging() {},
  },
};
</script>
<style lang="less" >
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.listExam {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        .radioGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-content: center;
          label {
            margin: 3px 0;
            display: flex;
            justify-content: flex-start;
          }
          .el-radio__label {
            width: 100%;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    // &::before {
    //   content: "";
    //   border-left: 4px solid #4574f9;
    //   width: 4px;
    //   height: 16px;
    //   position: absolute;
    //   left: -17px;
    // }
  }
}
.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
  > div {
    display: flex;
    align-items: center;
    font-size: 18px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.searchbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}
.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}
.el-checkbox__inner {
  width: 18px;
  height: 18px;
  &:after {
    height: 10px;
    left: 6px;
  }
}
.questionTitle{
  img{
    width: 150px;
    height: 100px;
  }
}
</style>
